import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
    DiJavascript1,
    DiReact,
    DiNodejs,
    DiMongodb,
    DiPython,
    DiGit, DiJava, DiMysql, DiDocker,
} from "react-icons/di";
import {
    SiPytorch,
    SiFirebase,
    SiNextdotjs, SiSpringboot, SiSpring, SiKubernetes, SiElasticsearch,
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={4} md={2} className="tech-icons">
            <DiJava />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            <SiSpring />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            <DiPython />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            <SiKubernetes />
        </Col>

        <Col xs={4} md={2} className="tech-icons">
            <SiElasticsearch />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            <DiMysql />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            <DiDocker />
        </Col>


        <Col xs={4} md={2} className="tech-icons">
            <CgCPlusPlus />
        </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
      {/*<Col xs={4} md={2} className="tech-icons">*/}
      {/*  <DiNodejs />*/}
      {/*</Col>*/}
      {/*<Col xs={4} md={2} className="tech-icons">*/}
      {/*  <DiReact />*/}
      {/*</Col>*/}

      {/*<Col xs={4} md={2} className="tech-icons">*/}
      {/*  <SiNextdotjs />*/}
      {/*</Col>*/}
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      {/*<Col xs={4} md={2} className="tech-icons">*/}
      {/*  <SiFirebase />*/}
      {/*</Col>*/}
    </Row>
  );
}

export default Techstack;
